/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonApply {
    classButton = 'button--apply'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let buttonId = $(this).data('page')
            let buttonAlt = $(this).data('alt')

            window.appJobs.FormApply.pageId = buttonId
            window.appJobs.FormApply.altPage = buttonAlt
        })
    }
}
