<template lang="pug">
label.input-text.input-text-right
  span.input-text__top
    span.input-text__label Password
    span.input-text__right(@click="passwordGeneration()") Generation
  input.input-text__input(
    type="text"
    placeholder=""
    v-model.trim="value"
    @keyup="changeFieldValue()"
  )
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: 'FieldPassword',

  data () {
    return {
      value: ''
    }
  },

  computed: {
    ...mapGetters([
      'fieldPassword'
    ])
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    ...mapActions([
        'actionPasswordGeneration'
    ]),

    changeFieldValue () {
      this.changeField({
        name: 'fieldPassword',
        value: this.value
      })
    },

    passwordGeneration () {
      this.actionPasswordGeneration()
      this.value = this.fieldPassword
    }
  }
}
</script>
