/*global $*/
/*eslint no-undef: "error"*/
import Cookies from 'js-cookie'

export default class AjaxFavorite {
    cookieName = 'newjobs4you-favorite'
    cookieNameUser = 'newjobs4you-login'
    classButton = 'ajax-favorite'
    classValueAll = 'ajax-favorite-value'
    classActive = 'active'

    constructor() {
        let self = this
        let objButton = $(`.${self.classButton}`)
        let objValueAll = $(`.${self.classValueAll}`)

        // добавляем id user (если его нет, то 0)
        let userId = Cookies.get(self.cookieNameUser) ? Cookies.get(self.cookieNameUser) : 0

        // выводим кол-во в кноке "Wish list"
        if (parseInt(objValueAll.length) === 1) {
            if (Cookies.get(self.cookieName)) {
                let result = JSON.parse(Cookies.get(self.cookieName)).filter(item => {
                    return (item.userId === userId)
                })

                objValueAll.text((result.length))
            }
        }

        // добавляем активность, если сущность выбрана
        objButton.each((key, item) => {
            let result = []
            let type = $(item).data('type')
            let id = $(item).data('id')

            if (Cookies.get(self.cookieName)) {
                result = JSON.parse(Cookies.get(self.cookieName))
            }

            const inActive = result.some(item => {
                return (item.id === id) && (item.type === type) && (item.userId === userId)
            })

            if (inActive) {
                $(item).addClass(self.classActive)
            }
        })

        objButton.click(function () {
            let result = []
            let type = $(this).data('type')
            let id = $(this).data('id')
            let inArray = false

            $(this).toggleClass(self.classActive)

            if (Cookies.get(self.cookieName)) {
                result = JSON.parse(Cookies.get(self.cookieName))
                inArray = result.some(item => {
                    return (item.id === id) && (item.type === type) && (item.userId === userId)
                })
            }

            if (!inArray) {
                result.push({
                    id: id,
                    type: type,
                    userId: userId
                })
            } else {
                if (!$(this).hasClass(self.classActive)) {
                    result = result.filter(item => {
                        return !((item.id === id) && (item.type === type) && (item.userId === userId))
                    })
                }
            }

            // после изменения, изменяем кнопку "Wish list"
            let allResult = result.filter(item => {
                return (item.userId === userId)
            })
            objValueAll.text(allResult.length)

            // сохраняем cookie
            Cookies.set(self.cookieName, JSON.stringify(result), { expires: 60 })
        })
    }
}
