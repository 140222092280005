/*global $*/
/*eslint no-undef: "error"*/

import Cookies from "js-cookie";

export default class AjaxLike {
    cookieName = 'jobs-like'
    classButton = 'ajax-like'
    classActive = 'active'
    classCount = 'ajax-like--count'

    constructor () {
        let self = this
        let objButton = $(`.${self.classButton}`)

        objButton.each((key, item) => {
            let result = []
            let currentId = $(item).data('id')

            if (Cookies.get(self.cookieName)) {
                result = JSON.parse(Cookies.get(self.cookieName))
            }

            const inActive = result.some(item => {
                return item === currentId
            })

            if (inActive) {
                $(item).addClass(self.classActive)
            }
        })

        objButton.click(function () {
            let result = []
            let inArray = false

            let currentId = $(this).data('id')
            let isActive = !$(this).hasClass(self.classActive)
            let currentActive = (isActive) ? 1 : 0

            $(this).toggleClass(self.classActive)

            if (Cookies.get(self.cookieName)) {
                result = JSON.parse(Cookies.get(self.cookieName))
                inArray = result.some(item => {
                    return item === currentId
                })
            }

            if (!inArray) {
                result.push(currentId)
            } else {
                if (!isActive) {
                    result = result.filter(item => {
                        return !(item === currentId)
                    })
                }
            }

            Cookies.set(self.cookieName, JSON.stringify(result), { expires: 60 })

            if (location.hostname !== 'localhost') {
                fetch(`/api/v1/like/${currentId}/${currentActive}/`)
                    .then(response => response.json())
                    .then(data => {
                        $(this).find(`.${self.classCount}`).text(data.like)
                    })
            } else {
                console.log('local like')
            }
        })
    }
}
