<template lang="pug">
label.input-text
  .input-text__label E-mail
  input.input-text__input(
    type="email"
    placeholder=""
    v-model.trim="value"
    @keyup="changeFieldValue()"
  )
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'FieldEmail',

  data () {
    return {
      value: ''
    }
  },

  computed: {
    ...mapGetters([
      'fieldEmail'
    ])
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    changeFieldValue () {
      this.changeField({
        name: 'fieldEmail',
        value: this.value
      })
    }
  },

  created() {
    this.value = this.fieldEmail
  }
}
</script>
