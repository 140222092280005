<template lang="pug">
.topic-create-button(@click="clickIsCreate()")
  svg.topic-create-button__icon
    use(xlink:href="#faq-plus")
  .topic-create-button__name Create a topic
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Button',

  computed: {
    ...mapGetters([
        'isCreate'
    ])
  },

  methods: {
    ...mapMutations([
        'changeIsCreate'
    ]),

    clickIsCreate () {
      this.changeIsCreate(!this.isCreate)
    }
  }
}
</script>
