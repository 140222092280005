<template lang="pug">
.input-text.input-text-right
  .input-text__top
    label.input-text__label(for="field-login-password") Password * <span class="input-text__label-info">15 chars</span>
    .input-text__right(@click="passwordGeneration()") Generation
  input.input-text__input(
    id="field-login-password"
    type="text"
    placeholder=""
    v-model.trim="value"
    @change="changeFieldValue()"
    @keyup="changeFieldValue()"
  )
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'FieldPassword',

  data () {
    return {
      value: ''
    }
  },

  computed: {
    ...mapGetters([
        'fieldPassword'
    ])
  },

  methods: {
    ...mapActions([
      'actionPasswordGeneration'
    ]),

    ...mapMutations([
      'changeField'
    ]),

    changeFieldValue () {
      this.changeField({
        name: 'fieldPassword',
        value: this.value
      })
    },

    passwordGeneration () {
      this.actionPasswordGeneration()
      this.value = this.fieldPassword
    }
  }
}
</script>
