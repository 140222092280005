export default {
    fieldPhone:       s => s.fieldPhone,
    fieldPassword:    s => s.fieldPassword,
    fieldConsent:     s => s.fieldConsent,
    consentText:      s => s.consentText,
    termsTitle:       s => s.termsTitle,
    termsDescription: s => s.termsDescription,
    buttonText:       s => s.buttonText,
    urlPersonal:      s => s.urlPersonal,
    isError:          s => s.isError,
    isEmail:          s => s.isEmail
}
