<template lang="pug">
.file-xls
    p Загружаем данный с форм:
    ul
      li №1 (подписаться)
      li №2 (партнеры)
      li №3 (отзывы)
      li №4 (apply now)

    .file-xls__buttons
      .file-xls__button
        .button(@click="getSave(1)")
          .button__text Загрузить ( 1 )
      .file-xls__button
        .button(@click="getSave(2)")
          .button__text Загрузить ( 2 )
      .file-xls__button
        .button(@click="getSave(3)")
          .button__text Загрузить ( 3 )
      .file-xls__button
        .button(@click="getSave(4)")
          .button__text Загрузить ( 4 )

    p.bold(v-show="parseInt(isTotal.count) > 0") Скачайте файл по <a :href="isTotal.filePath" target="_blank" download>ссылки</a>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppFileXls',

  data () {
    return {
      show: false
    }
  },

  computed: {
    ...mapGetters([
        'isTotal'
    ])
  },

  methods: {
    ...mapActions([
        'Save'
    ]),

    getSave (number) {
      this.Save(number)
    }
  }
}
</script>
