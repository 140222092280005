<template lang="pug">
.topic-create-photo
  label.topic-create-photo__label(:class="{'topic-create-photo-corrected': fileChange}" v-if="!fileSrc")
    a(:href="fileSrc" data-fancybox="topic-create")
    input.topic-create-photo__input(
      type="file"
      name="files"
      ref="files"
      id="files"
      @change="onFileChange"
    )
    svg.topic-create-photo__icon
      use(xlink:href="#forum-form-photo")
    .topic-create-photo__text
      .topic-create-photo__title(v-html="fileText")
      .topic-create-photo__info jpg, jpeg, png
  .topic-create-photo__download(v-else)
    a.topic-create-photo__img(:href="fileSrc" data-fancybox="topic-create")
      img.topic-create-photo__img-wrapper(:src="fileSrc")
    svg.topic-create-photo__close(@click="onClose()")
      use(xlink:href="#faq-plus")

</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Photo',

  data () {
    return {
      files: '',
      fileText: 'Add photo',
      fileChange: false,
      fileSrc: ''
    }
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files
      this.fileChange = true

      this.changeFieldValue()

      if (this.fileChange) {
        this.fileText = 'Photo corrected'
      }

      // img download
      let file = this.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.fileSrc = reader.result
      }
    },

    changeFieldValue () {
      this.changeField({
        name: 'fieldImg',
        value: this.files
      })
    },

    onClose () {
      this.files = ''
      this.fileSrc = ''
      this.fileChange = false
      this.fileText = 'Add photo'

      this.changeField({
        name: 'fieldImg',
        value: ''
      })
    }
  }
}
</script>
