<template lang="pug">
.personal-logout
  svg.personal-logout__icon
    use(xlink:href="#icon-logout")
  a.personal-logout__link(:href="curHref") Log out
</template>

<script>
export default {
  name: 'LogOut',

  data () {
    return {
      curHref: location.pathname + '?login=logout'
    }
  }
}
</script>
