<template lang="pug">
.button(
  :class="{'button-disable': isSend}"
  @click="createTopic()"
)
  .button__text Publish
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Button',

  props: [
    'isSend',
    'fieldUserId'
  ],

  computed: {
    ...mapGetters([
        'fieldTitle',
        'fieldText',
        'fieldSection',
        'fieldImg',
        'isTopicCreate'
    ])
  },

  methods: {
    ...mapMutations([
        'changeIsTopicCreate',
        'changeField'
    ]),
    ...mapActions([
        'actionCreateTopic',
    ]),
    createTopic () {
      if (!this.isSend) {
        this.actionCreateTopic({
          fields: {
            fieldUserId: this.fieldUserId,
            fieldTitle: this.fieldTitle,
            fieldText: this.fieldText,
            fieldSection: this.fieldSection
          },
          files: this.fieldImg
        })

        this.changeField({
          name: 'fieldTitle',
          value: ''
        })

        this.changeField({
          name: 'fieldText',
          value: ''
        })

        this.changeField({
          name: 'fieldSection',
          value: ''
        })

        this.changeField({
          name: 'fieldImg',
          value: ''
        })

        this.changeIsTopicCreate(true)
        setTimeout(() => {
          this.changeIsTopicCreate(false)
        }, 3000)
      }
    }
  }
}
</script>
