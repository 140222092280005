/*global $*/
/*eslint no-undef: "error"*/

export default class MenuForum {
    classButton = 'menu-personal-toggle--button'
    classButtonOpen = 'menu-personal-toggle-open'
    classBlock = 'menu-personal--block'
    classBlockOpen = 'menu-personal-forum-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonOpen)
            $(`.${self.classBlock}`).toggleClass(self.classBlockOpen)
        })
    }
}
