<template lang="pug">
.form-login
  .form-login__fields
    FieldFullName
    FieldEmail
    FieldUserName
    FieldPhone
    FieldPassword
    FieldState

  .form-login__terms
    Terms

  .form-login__consent
    Consent

  .form-login__button
    Button(:isSend="isSend")
</template>

<script>
import FieldFullName from './components/fields/FieldFullName'
import FieldEmail from './components/fields/FieldEmail'
import FieldUserName from './components/fields/FieldUserName'
import FieldPhone from './components/fields/FieldPhone'
import FieldPassword from './components/fields/FieldPassword'
import FieldState from './components/fields/FieldState'

import Terms from './components/common/Terms'
import Consent from './components/common/Consent'
import Button from './components/common/Button'

export default {
  name: 'Form',

  props: [
    'isSend'
  ],

  components: {
    FieldFullName,
    FieldEmail,
    FieldUserName,
    FieldPhone,
    FieldPassword,
    FieldState,

    Terms,
    Consent,
    Button
  }
}
</script>
