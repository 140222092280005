<template lang="pug">
.block-email
  p.center(v-if="IsEmail === 'none'") If you forgot your password, click on the
    span.link(@click="getSend()")  link
  p.center(v-else-if="IsEmail === 'error'") <span class="red">Ошибка, телефон ввдете не верно</span>
  p.center(v-else) <span class="bold green">Данные отправлены на почту {{ IsEmail }}</span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Email',

  data () {
    return {
      files: '',
      password: 'test'
    }
  },

  computed: {
    ...mapGetters([
        'isEmail'
    ]),

    IsEmail () {
      return this.isEmail
    }
  },

  methods: {
    ...mapActions([
        'SendMailPassword'
    ]),

    getSend () {
      this.SendMailPassword({
        setting: {
          mailDo: 'info@newjobs4you.com',
          emails: '',
          title: 'Забыл пароль',
          subject: 'Информация по новому паролю',
          type: 'password'
        },

        fields: [],
        files: this.files
      })
    }
  }
}
</script>
