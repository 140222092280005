export default {
  changeField (state, payload) {
    state[ payload.name ] = payload.value
  },

  changeConsentText (state, payload) {
    state.consentText = payload
  },

  changeTermsTitle (state, payload) {
    state.termsTitle = payload
  },

  changeTermsDescription (state, payload) {
    state.termsDescription = payload
  },

  changeButtonText (state, payload) {
    state.buttonText = payload
  },

  changeIsError (state, payload) {
    state.isError = payload
  },

  changeIsEmail (state, payload) {
    state.isEmail = payload
  },
}
