<template lang="pug">
.form-main
  .form-main__fields
    .form-main__field
      .input-text
        input.input-text__input(
          type="text"
          placeholder="Your Full Name *"
          v-model.trim="fieldName"
          :class="{'input-text__input-error': v$.fieldName.$invalid && (fieldName.length > 0), 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
        )
    .form-main__field
      .input-text
        input.input-text__input(
          type="email"
          placeholder="Your E-mail *"
          v-model.trim="fieldEmail"
          :class="{'input-text__input-error': v$.fieldEmail.$invalid && (fieldEmail.length > 0), 'input-text__input-complete': !v$.fieldEmail.$invalid && (fieldEmail.length > 0)}"
        )
    .form-main__field
      .input-text
        input.input-text__input(
          type="tel"
          placeholder="Your Phone *"
          v-model.trim="fieldPhone"
          :class="{'input-text__input-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0), 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
          v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
        )
    .form-main__field
      .input-text
        input.input-text__input(
          type="text"
          placeholder="Company Name *"
          v-model.trim="fieldCompany"
          :class="{'input-text__input-error': v$.fieldCompany.$invalid && (fieldCompany.length > 0), 'input-text__input-complete': !v$.fieldCompany.$invalid && (fieldCompany.length > 0)}"
        )
  .form-main__button
    .button(
      :class="{'button-disable': v$.$invalid}"
      @click="getSend()"
    )
      .button__text Send
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormMain',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      files: '',
      fieldName: '',
      fieldEmail: '',
      fieldPhone: '',
      fieldCompany: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        document.querySelector('#button-form-success').click()
        this.Send({
          setting: {
            form: {
              formId: '2',
              checkField: {
                name: 'phoneCheck',
                value: this.fieldPhone
              },
              isCheck: '1',
              pageId: window.appJobs.Form.pageId,
              altPage: window.appJobs.Form.altPage
            },
            mailDo: 'info@newjobs4you.com',
            emails: window.appJobs.Form.emails,
            title: 'Thank you for completing this form!',
            subject: 'We received your request, and our specialist will reach out to you within 24 hours by Email or phone Call "Partner."'
          },

          fields: [
            {
              name: 'name',
              title: 'Full Name',
              value: this.fieldName
            },
            {
              name: 'email',
              title: 'E-mail',
              value: this.fieldEmail
            },
            {
              name: 'phone',
              title: 'Phone',
              value: this.fieldPhone
            },
            {
              name: 'company',
              title: 'Company Name',
              value: this.fieldCompany
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldEmail = this.fieldPhone = this.fieldCompany = ''
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      fieldEmail: {
        required,
        email
      },
      fieldPhone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },
      fieldCompany: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      }
    }
  }
}
</script>
