<template lang="pug">
label.input-textarea
  .input-textarea__label Enter the description
  textarea.input-textarea__input(
    v-model.trim="value"
    :class="{'input-textarea__input-focus': isFocus  || value.length >= 1}"
    @focus="isFocus = true"
    @blur="isFocus = false"
    @keyup="changeFieldValue()"
  )
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Text',

  data () {
    return {
      value: '',
      isFocus: false
    }
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    changeFieldValue () {
      this.changeField({
        name: 'fieldText',
        value: this.value
      })
    }
  }
}
</script>
