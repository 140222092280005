export default {
  fieldPhone      : '',
  fieldPassword   : '',
  fieldConsent    : [],

  isEmail         : 'none',

  consentText     : '',

  termsTitle      : '',
  termsDescription: '',

  buttonText      : '',

  urlPersonal     : '/account/personal/',

  isError         : false
}
