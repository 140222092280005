<template lang="pug">
label.input-text
  span.input-text__label Password * <span class="input-text__label-info">15 chars</span>
  input.input-text__input(
    type="password"
    placeholder=""
    v-model.trim="value"
    @keyup="changeFieldValue()"
  )
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: "FieldPassword",

  data () {
    return {
      value: ''
    }
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    changeFieldValue () {
      this.changeField({
        name: 'fieldPassword',
        value: this.value
      })
    }
  }
}
</script>

<style scoped>

</style>
