<template lang="pug">
.form-feedback
  .form-feedback__fields
    .form-feedback__field
      .input-text.input-text-gray
        label.input-text__label(for="feedback-field-name") Full Name *
        input.input-text__input(
          id="feedback-field-name"
          type="text"
          v-model.trim="fieldName"
          :class="{'input-text__input-error': v$.fieldName.$invalid && (fieldName.length > 0), 'input-text__input-complete': !v$.fieldName.$invalid && (fieldName.length > 0)}"
        )
    .form-feedback__field
      .input-textarea.input-textarea-gray
        label.input-textarea__label(for="feedback-field-name") Text Block *
        textarea.input-textarea__input(
          id="feedback-field-name"
          v-model.trim="fieldText"
          :class="{'input-textarea__input-error': v$.fieldText.$invalid && (fieldText.length > 0), 'input-textarea__input-complete': !v$.fieldText.$invalid && (fieldText.length > 0)}"
        )
  .form-feedback__stars
    .form-feedback__star
      .stars.stars-white
        .stars__items
          .stars__item
            .stars__title Equipment<br>Score:
          .stars__item
            .stars__list
              svg.stars__icon(
                v-for="(item, index) of 5"
                :key="index"
                :class="{'stars__icon-plus': fieldStars1 >= item}"
                :data-id="item"
                @click="fieldStars1 = item"
              )
                use(xlink:href="#star-plus")

    .form-feedback__star
      .stars.stars-white
        .stars__items
          .stars__item
            .stars__title Equipment<br>Score:
          .stars__item
            .stars__list
              svg.stars__icon(
                v-for="(item, index) of 5"
                :key="index"
                :class="{'stars__icon-plus': fieldStars2 >= item}"
                :data-id="item"
                @click="fieldStars2 = item"
              )
                use(xlink:href="#star-plus")
    .form-feedback__star
      .stars.stars-white
        .stars__items
          .stars__item
            .stars__title Equipment<br>Score:
          .stars__item
            .stars__list
              svg.stars__icon(
                v-for="(item, index) of 5"
                :key="index"
                :class="{'stars__icon-plus': fieldStars3 >= item}"
                :data-id="item"
                @click="fieldStars3 = item"
              )
                use(xlink:href="#star-plus")
    .form-feedback__star
      .stars.stars-white
        .stars__items
          .stars__item
            .stars__title Equipment<br>Score:
          .stars__item
            .stars__list
              svg.stars__icon(
                v-for="(item, index) of 5"
                :key="index"
                :class="{'stars__icon-plus': fieldStars4 >= item}"
                :data-id="item"
                @click="fieldStars4 = item"
              )
                use(xlink:href="#star-plus")
  .form-feedback__consent
    label.consent.consent-gray
      input.consent__input(type="checkbox" v-model="consent" value="consent" checked="checked")
      span.consent__blocks
        span.consent__button
        span.consent__label I agree to the Terms of Use & Privacy Policy*
  .form-feedback__button
    .button(
      :class="{'button-disable': v$.$invalid}"
      @click="getSend()"
    )
      .button__text Submit
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormFeedback',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      files: '',
      fieldName: '',
      fieldText: '',
      fieldStars1: 4,
      fieldStars2: 4,
      fieldStars3: 4,
      fieldStars4: 4,
      consent: ['consent']
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        document.querySelector('#button-form-success').click()
        this.Send({
          setting: {
            form: {
              formId: '3',
              checkField: {
                name: '',
                value: ''
              },
              isCheck: '0',
              pageId: window.appJobs.Form.pageId,
              altPage: window.appJobs.Form.altPage
            },
            mailDo: 'info@newjobs4you.com',
            emails: window.appJobs.Form.emails,
            title: 'Thank you, we received your feedback',
            subject: 'Your review will be added after Admin approval "Give feedback" form!'
          },

          fields: [
            {
              name: 'name',
              title: 'Full Name',
              value: this.fieldName
            },
            {
              name: 'text',
              title: 'Text',
              value: this.fieldText
            },
            {
              name: 'fieldStars1',
              title: 'Equipment score',
              value: this.fieldStars1
            },
            {
              name: 'fieldStars2',
              title: 'Home-time score',
              value: this.fieldStars2
            },
            {
              name: 'fieldStars3',
              title: 'Dispatch score',
              value: this.fieldStars3
            },
            {
              name: 'fieldStars4',
              title: 'Salary score',
              value: this.fieldStars4
            },
            {
              name: 'pageId',
              title: 'Page id',
              value: window.appJobs.Form.pageId
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldText= ''
        this.fieldStars1 = this.fieldStars2 = this.fieldStars3 = this.fieldStars4 = 4
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      fieldText: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(300)
      },
      consent: {
        required
      }
    }
  }
}
</script>
