/*global $*/
/*eslint no-undef: "error"*/

export default class HeaderMobile {
    classButtonOpen = 'menu-toggle'
    classButtonClose = 'header-mobile__close'
    classBlock = 'header-mobile'
    classBlockActive = 'header-mobile-active'

    constructor () {
        let self = this

        $(`.${self.classButtonOpen}`).click(function () {
            $(`.${self.classBlock}`).toggleClass(self.classBlockActive)
        })

        $(`.${self.classButtonClose}`).click(function () {
            $(`.${self.classBlock}`).toggleClass(self.classBlockActive)
        })
    }
}
