<template lang="pug">
.button(
  :class="{'button-disable': isSend}"
  @click="updateUser()"
)
  .button__text(v-html="buttonText")
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Button',

  props: [
    'isSend'
  ],

  computed: {
    ...mapGetters([
      'buttonText',

      'fieldFullName',
      'fieldEmail',
      'fieldUserName',
      'fieldPhone',
      'fieldState',
      'fieldPassword',

      'files',

      'cookieHash'
    ]),

    text () {
      return this.buttonText
    }
  },

  methods: {
    ...mapActions([
       'actionUpdateUser'
    ]),

    ...mapMutations([
        'changeField'
    ]),

    updateUser() {
      if (!this.isSend) {
        this.actionUpdateUser({
          fields: {
            cookieHash: this.cookieHash,

            fullName: this.fieldFullName,
            email: this.fieldEmail,
            userName: this.fieldUserName,
            phone: this.fieldPhone,
            password: this.fieldPassword,
            state: this.fieldState,
          },
          files: this.files
        })

        this.changeFieldValue()
      }
    },

    changeFieldValue () {
      this.changeField({
        name: 'fieldConsent',
        value: []
      })
    }
  }
}
</script>
