<template lang="pug">
.form-login
  .form-login__fields.form-login__fields-1
    FieldPhone
    FieldPassword
  .form-login__email
    Email
  .form-login__terms
    Terms
  .form-login__consent
    Consent
  .form-login__error
    Error(v-if="error")
  .form-login__button
    Button(:isSend="v$.$invalid")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'

import { mapGetters, mapMutations } from 'vuex'

import FieldPhone from './components/fields/FieldPhone'
import FieldPassword from './components/fields/FieldPassword'

import Email from './components/common/Email'

import Terms from './components/common/Terms'
import Consent from './components/common/Consent'
import Button from './components/common/Button'

import Error from './components/common/Error'

export default {
  name: 'AppFormLogin',

  components: {
    FieldPhone,
    FieldPassword,
    Email,
    Terms,
    Consent,
    Button,
    Error
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      objectConsent: {},
      objectForm: {}
    }
  },

  computed: {
    ...mapGetters([
        'fieldPhone',
        'fieldPassword',
        'fieldConsent',
        'isError'
    ]),

    phone () {
      return this.fieldPhone
    },

    password () {
      return this.fieldPassword
    },

    consent () {
      return this.fieldConsent
    },

    error () {
      return this.isError
    }
  },

  methods: {
    ...mapMutations([
      'changeTermsTitle',
      'changeTermsDescription',
      'changeConsentText',
      'changeButtonText'
    ])
  },

  validations () {
    return {
      phone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },

      password: {
        required,
        minLength: minLength(15),
        maxLength: maxLength(15)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.objectConsent = window.appJobs.Consent
    this.objectForm = window.appJobs.FormLogin

    this.changeTermsTitle(this.objectConsent.terms.title)
    this.changeTermsDescription(this.objectConsent.terms.description)

    this.changeConsentText(this.objectConsent.text)

    this.changeButtonText(this.objectForm.button)
  }
}
</script>
