<template lang="pug">
.filter-states
  label.filter-states__top(for="search-state-popup")
    svg.filter-states__icon
      use(xlink:href="#icon-search")
    input.filter-states__input(
      id="search-state-popup"
      type="text"
      placeholder="Search States"
      v-model="searchState"
    )

  .filter-states__bottom
    .filter-states__list
      .filter-states__item.filter-states__item-active(
        v-for="(state, index) of searchActive.slice(0, 5)"
        :key="index"
      )
        a.filter-states__name.filter-states__name-active(:href="state.link") {{ state.name }}
        svg.filter-states__checked
          use(xlink:href="#icon-checked")

      .filter-states__item
        a.filter-states__name.filter-states__name-active(:href="allStates.link") {{ allStates.name }}

      .filter-states__item(
        v-for="(state, index) of searchList.slice(0, countStates)"
        :key="index"
      )
        a.filter-states__name(:href="state.link") {{ state.name }}

    .filter-states__more
      .filter-states__more-name(@click="setCountStates()") {{ buttonName }}
</template>

<script>
export default {
  name: 'AppFilterStates',

  data () {
    return {
      searchState: '',
      statesAll: [],
      statesList: [],
      allStates: {},
      countStates: 5,
      buttonName: ''
    }
  },

  computed: {
    searchList () {
      return this.statesList.filter(state => {
        return state.name.toLowerCase().includes(this.searchState.toLowerCase())
      })
    },

    searchActive () {
      return this.statesList.filter(state => {
        return parseInt(state.active) === 1
      })
    }
  },

  methods: {
    setCountStates () {
      this.countStates = this.statesAll.length

      if (this.buttonName === this.allStates.buttonAll) {
        this.buttonName = this.allStates.buttonHide
        this.countStates = this.statesAll.length
      } else {
        this.buttonName = this.allStates.buttonAll
        this.countStates = 5
      }
    }
  },

  created() {
    let states = window.appJobs.States
    this.statesAll = states.list
    this.statesList = states.list
    this.buttonName = states.allStates.buttonAll
    this.allStates = states.allStates
  }
}
</script>
