<template lang="pug">
label.input-text
  .input-text__label Full Name
  input.input-text__input(
    type="text"
    placeholder=""
    v-model.trim="value"
    @keyup="changeFieldValue()"
  )
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'FieldFullName',

  data () {
    return {
      value: ''
    }
  },

  computed: {
    ...mapGetters([
        'fieldFullName'
    ])
  },

  methods: {
    ...mapMutations([
        'changeField'
    ]),

    changeFieldValue () {
      this.changeField({
        name: 'fieldFullName',
        value: this.value
      })
    }
  },

  created() {
    this.value = this.fieldFullName
  }
}
</script>
