export default {
    optionSize:             s => s.optionSize,
    fieldName:              s => s.fieldName,
    fieldFullName:          s => s.fieldFullName,
    fieldEmail:             s => s.fieldEmail,
    fieldPhone:             s => s.fieldPhone,
    fieldPassword:          s => s.fieldPassword,
    fieldPasswordConfirm:   s => s.fieldPasswordConfirm,
    fieldConsent:           s => s.fieldConsent,
    consentText:            s => s.consentText,
    termsTitle:             s => s.termsTitle,
    termsDescription:       s => s.termsDescription,
    buttonText:             s => s.buttonText,
    cookieName:             s => s.cookieName,
    cookieExpires:          s => s.cookieExpires,
    urlPersonal:            s => s.urlPersonal,
    creatError:             s => s.creatError
}
