export default {
    async actionCreateTopic (context, payload) {
        if ((process.env.NODE_ENV === 'development')) {
            console.log(payload)
        } else {
            const fields = {
                fields: payload.fields
            }

            const formData = new FormData()
            formData.append('fields', JSON.stringify(fields))

            if (payload.files !== '') {
                for (let i = 0; i < payload.files.length; i++) {
                    const file = payload.files[i]
                    formData.append('files[' + i + ']', file)
                }
            }

            const response = await fetch(`/api/v1/topic/create/`, {
                method: 'POST',
                body: formData
            })

            const result = await response.json()
            console.log(result)
        }
    }
}
