<template lang="pug">
div
  .input-text__label Choose the topic
  Multiselect(v-model="value" :options="options" @select="changeFieldValue()")
</template>

<script>
import { mapMutations } from 'vuex'
import Multiselect from '@vueform/multiselect'

export default {
  name: 'Select',

  props: [
    'fieldSections'
  ],

  components: {
    Multiselect
  },

  data() {
    return {
      value: null,
      options: []
    }
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    changeFieldValue () {
      console.log(this.value)
      this.changeField({
        name: 'fieldSection',
        value: this.value
      })
    }
  },

  created() {
    this.options = this.fieldSections.split(',')
  }
}
</script>
