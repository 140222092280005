<template lang="pug">
.form-personal
  .form-personal__img
    Image
    LogOut

  .form-personal__form
    Form(:isSend="v$.$invalid")

</template>

<script>
import Cookies from 'js-cookie'

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { mapMutations, mapGetters } from 'vuex'

import Image from './components/common/Image/Image'
import LogOut from './components/common/LogOut/LogOut'
import Form from './components/common/Form/Form'

export default {
  name: 'AppFormPersonal',

  components: {
    Image,
    LogOut,
    Form
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      objectConsent: {},
      objectPersonal: {}
    }
  },

  computed: {
    ...mapGetters([
      'fieldConsent',
      'cookieName',
      'urlRedirect'
    ]),

    consent () {
      return this.fieldConsent
    }
  },

  methods: {
    ...mapMutations([
      'changeTermsTitle',
      'changeTermsDescription',
      'changeConsentText',
      'changeButtonText',
      'changeField',
      'changeCookieHash'
    ])
  },

  validations () {
    return {
      consent: {
        required
      }
    }
  },

  created () {
    if (Cookies.get(this.cookieName)) {
      this.changeCookieHash(Cookies.get(this.cookieName))
    }

    this.objectConsent = window.appJobs.Consent
    this.objectPersonal = window.appJobs.FormPersonal

    this.changeField({name: 'fieldFullName', value: this.objectPersonal.fields.fullName})
    this.changeField({name: 'fieldEmail', value: this.objectPersonal.fields.email})
    this.changeField({name: 'fieldUserName', value: this.objectPersonal.fields.userName})
    this.changeField({name: 'fieldPhone', value: this.objectPersonal.fields.phone})
    this.changeField({name: 'fieldState', value: this.objectPersonal.fields.state})
    this.changeField({name: 'fieldImg', value: this.objectPersonal.fields.img})

    this.changeConsentText(this.objectConsent.text)
    this.changeTermsTitle(this.objectConsent.terms.title)
    this.changeTermsDescription(this.objectConsent.terms.description)
    this.changeButtonText(this.objectPersonal.button)
  }
}
</script>
