<template lang="pug">
.form-index__fields
  .form-index__field
    .input-text
      input.input-text__input(
        type="email"
        placeholder="E-mail *"
        v-model.trim="fieldEmail"
        :class="{'input-text__input-error': v$.fieldEmail.$invalid && (fieldEmail.length > 0), 'input-text__input-complete': !v$.fieldEmail.$invalid && (fieldEmail.length > 0)}"
      )
  .form-index__field
    .button(
      :class="{'button-disable': v$.$invalid}"
      @click="getSend()"
    )
      .button__text Subscribe
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormSubscribePersonal',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      files: '',
      fieldEmail: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.Send({
          setting: {
            form: {
              formId: '1',
              checkField: {
                name: 'emailCheck',
                value: this.fieldEmail
              },
              isCheck: '1',
              pageId: window.appJobs.Form.pageId,
              altPage: window.appJobs.Form.altPage
            },
            mailDo: 'info@newjobs4you.com',
            emails: window.appJobs.Form.emails,
            title: 'Thank you for subscribing!',
            subject: 'You will receive our emails with hot information. "Subscribe Index"'
          },

          fields: [
            {
              name: 'email',
              title: 'E-mail',
              value: this.fieldEmail
            }
          ],
          files: this.files
        })

        this.fieldEmail = ''

        document.querySelector('#button-form-success').click()
        setTimeout(() => {
          window.location.replace('/job-board/all-options/')
        }, 3000)
      }
    }
  },

  validations () {
    return {
      fieldEmail: {
        required,
        email
      }
    }
  }
}
</script>
