<template lang="pug">
.search-form(:class="{'search-form-result': searchLine.length >= searchMinChar}")
  label.search-form__line
    input.search-form__input(
      type="text"
      :placeholder="settings.placeholderText"
      v-model="searchLine"
    )
    svg.search-form__icon
      use(xlink:href="#icon-search")

  .search-form__list
    .search-form__result(
      v-for="(item, index) of searchList.slice(0, searchCount)"
      :key="index"
    )
      a.search-form__link(:href="item.url" v-html="item.name")

    .search-form__more(@click="setCountResults()" v-show="(searchList.length > searchCount) || (buttonName === settings.buttonHide)")
      .search-form__more-name {{ buttonName }}

</template>

<script>
export default {
  name: 'AppSearch',

  data () {
    return {
      settings: {},
      data: [],
      searchLine: '',
      searchMinChar: 3,
      searchCount: 5,
      buttonName: ''
    }
  },

  computed: {
    searchList () {
      if (this.searchLine.length >= this.searchMinChar) {
        return this.data.filter(item => {
          return item.searchText.toLowerCase().includes(this.searchLine.toLowerCase())
        })
      } else {
        return []
      }
    }
  },

  methods: {
    setCountResults () {
      this.searchCount = this.data.length

      if (this.buttonName === this.settings.buttonAll) {
        this.buttonName = this.settings.buttonHide
        this.searchCount = this.data.length
      } else {
        this.buttonName = this.settings.buttonAll
        this.searchCount = parseInt(this.settings.searchCount)
      }
    }
  },

  created() {
    this.settings = window.appSearchData.setting
    this.data = window.appSearchData.data
    this.buttonName = this.settings.buttonAll
    this.searchMinChar = parseInt(this.settings.searchMinChar)
    this.searchCount = parseInt(this.settings.searchCount)
  }
}
</script>
