/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonMoreFilter {
    classBlock = 'button-more-filter--block'
    classItem = 'button-more-filter--item'
    classItemHidden = 'filter-prop-hidden'
    classButton = 'button-more-filter--button'
    classButtonHidden = 'button-more-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).removeClass(self.classItemHidden)
            $(this).addClass(self.classButtonHidden)
        })
    }
}
