<template lang="pug">
.topic-create-form
  .topic-create-form__item(v-if="!topicCreate")
    .h4 Create a topic
    .topic-create-form__close
      Close
    .topic-create-form__fields
      .topic-create-form__field
        Title
      .topic-create-form__field
        Text
      .topic-create-form__field.topic-create-form__field-bottom
        .topic-create-form__bottom
          .topic-create-form__bottom-item
            .topic-create-form__select
              Select(:field-sections="fieldSections")
            .topic-create-form__photo
              Photo
          .topic-create-form__bottom-item
            .topic-create-form__button
              Button(:isSend="v$.$invalid" :field-user-id="fieldUserId")
  .topic-create-form__item(v-else)
    Success
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {minLength, required} from '@vuelidate/validators'

import { mapGetters } from 'vuex'

import Title from '@/components/Topic/TopicCreate/components/fields/Title'
import Text from '@/components/Topic/TopicCreate/components/fields/Text'
import Select from '@/components/Topic/TopicCreate/components/fields/Select'
import Photo from '@/components/Topic/TopicCreate/components/Photo'
import Button from '@/components/Topic/TopicCreate/components/fields/Button'
import Close from '@/components/Topic/TopicCreate/components/Close'
import Success from '@/components/Topic/TopicCreate/components/Success'

export default {
  name: 'Form',

  props: [
    'fieldSections',
    'fieldUserId'
  ],

  components: {
    Title,
    Text,
    Select,
    Photo,
    Button,
    Close,
    Success
  },

  setup () {
    return { v$: useVuelidate() }
  },

  computed: {
    ...mapGetters([
        'fieldTitle',
        'fieldText',
        'fieldSection',
        'isTopicCreate'
    ]),

    title () {
      return this.fieldTitle
    },

    topicCreate () {
      return this.isTopicCreate
    }
  },

  validations () {
    return {
      title: {
        required,
        minLength: minLength(10)
      }
    }
  },
}
</script>

<style scoped>

</style>
