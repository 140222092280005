/*global $*/
/*eslint no-undef: "error"*/
export default class FilterToggle {
    classButton = 'filter-toggle--button'
    classButtonOpen = 'filter-toggle-open'
    classForm = 'filter-toggle--form'
    classFormOpen = 'filter-form-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonOpen)

            $(`.${self.classForm}`).toggleClass(self.classFormOpen)
        })
    }
}
