export default {
    async actionLogin (context, payload) {
        if ((process.env.NODE_ENV === 'development')) {
            console.log(payload)
        } else {
            const fields = {
                fields: payload
            }

            const formData = new FormData()
            formData.append('fields', JSON.stringify(fields))

            const response = await fetch(`/api/v1/user/isLogin/`, {
                method: 'POST',
                body: formData
            })

            const result = await response.json()
            console.log(result)

            if (result.isAccount) {
                context.commit('changeIsError', false)
                window.location.href = context.getters.urlPersonal
            } else {
                context.commit('changeIsError', true)
                console.log('no')
            }
        }
    },

    async SendMailPassword (context, payload) {
        if ((process.env.NODE_ENV === 'development')) {
            context.commit('changeIsEmail', '***@**.**')
            console.log(payload)

            setTimeout(() => {
                context.commit('changeIsEmail', 'none')
            }, 5000)
        } else {
            let fieldPhone = context.getters.fieldPhone

            const fields = {
                mailDo: payload.setting.mailDo,
                emails: payload.setting.emails,
                title: payload.setting.title,
                subject: payload.setting.subject,
                fields: payload.fields
            }

            const formData = new FormData()
            formData.append('fields', JSON.stringify(fields))

            if (payload.files !== '') {
                for (let i = 0; i < payload.files.length; i++) {
                    let file = payload.files[i]
                    formData.append('files[' + i + ']', file)
                }
            }

            const response = await fetch(`/api/v1/send_form_password/${fieldPhone}/`, {
                method: 'POST',
                body: formData
            })

            const result = await response.json()
            context.commit('changeIsEmail', result)

            setTimeout(() => {
                context.commit('changeIsEmail', 'none')
            }, 5000)
            console.log(result)
        }
    }
}
