/*global $*/
/*eslint no-undef: "error"*/

export default class TopicRead {
    classBlock = 'topic-content'
    classButton = 'topic-content--button'
    classButtonClose = 'topic-content__button-close'
    classPreview = 'topic-content__preview'
    classPreviewClose = 'topic-content__preview-close'
    classText = 'topic-content__text'
    classTextOpen = 'topic-content__text-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).addClass(self.classButtonClose)
            $(this).closest(`.${self.classBlock}`).find(`.${self.classPreview}`).addClass(self.classPreviewClose)
            $(this).closest(`.${self.classBlock}`).find(`.${self.classText}`).addClass(self.classTextOpen)
        })
    }
}
