/*global $*/
/*eslint no-undef: "error"*/

export default class FaqCompany {
    classButton = 'faq-tabs__item'
    classButtonActive = 'faq-tabs__item-active'
    classButtonActiveMobile = 'faq-tabs__item-active-mobile'
    classTabs = 'faq__tabs'
    classTab = 'faq__tab'
    classTabActive = 'faq__tab-active'
    classBlocks = 'faq__blocks'
    classBlock = 'faq__block'
    classBlockHidden = 'faq__block-hidden'
    classBlockHiddenMobile = 'faq__block-hidden-mobile'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let tab = $(this).data('tab')
            let objTabs = $(`.${self.classTabs}`)
            let objBlocks = $(`.${self.classBlocks}`)
            const documentWidth = parseInt($(document).width())

            if (documentWidth > 640) {
                objTabs.find(`.${self.classButton}`).removeClass(self.classButtonActive)
                objTabs.find(`.${self.classButton}[data-tab="${tab}"]`).addClass(self.classButtonActive)

                objBlocks.find(`.${self.classBlock}`).addClass(self.classBlockHidden)
                objBlocks.find(`.${self.classBlock}[data-tab="${tab}"]`).removeClass(self.classBlockHidden)

                objBlocks.find(`.${self.classTab}`).removeClass(self.classTabActive)
                objBlocks.find(`.${self.classTab}[data-tab="${tab}"]`).addClass(self.classTabActive)

                objBlocks.find(`.${self.classButton}`).removeClass(self.classButtonActive)
                objBlocks.find(`.${self.classButton}[data-tab="${tab}"]`).addClass(self.classButtonActive)
            } else {
                if ($(this).hasClass(self.classButtonActiveMobile)) {
                    $(this).removeClass(self.classButtonActiveMobile)
                    objBlocks.find(`.${self.classBlock}`).removeClass(self.classBlockHiddenMobile)
                } else {
                    objTabs.find(`.${self.classButton}[data-tab="${tab}"]`).toggleClass(self.classButtonActive)

                    objBlocks.find(`.${self.classBlock}[data-tab="${tab}"]`).toggleClass(self.classBlockHidden)

                    objBlocks.find(`.${self.classTab}[data-tab="${tab}"]`).toggleClass(self.classTabActive)

                    objBlocks.find(`.${self.classButton}[data-tab="${tab}"]`).toggleClass(self.classButtonActive)
                }
            }
        })
    }
}
