<template lang="pug">
.form-error
  p.error.center Please specify another phone number, a user with such a phone already exists.
</template>

<script>
export default {
  name: 'Error'
}
</script>
