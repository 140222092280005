export default {
  actionPasswordGeneration (context) {
    let password = ''
    let CharacterSet = ''
    let optionSize = context.getters.optionSize

    CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
    CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    CharacterSet += '0123456789'
    CharacterSet += '![]{}()%&*$#^<>~@|'

    for(let i=0; i < optionSize; i++) {
      password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
    }

    context.commit('changeField', {name: 'fieldPassword', value: password})
  },

  async actionCreatUser (context, payload) {
    if ((process.env.NODE_ENV === 'development')) {
      console.log(payload)
    } else {
      const fields = {
        fields: payload
      }

      const formData = new FormData()
      formData.append('fields', JSON.stringify(fields))

      const response = await fetch(`/api/v1/user/creat/`, {
        method: 'POST',
        body: formData
      })

      const result = await response.json()
      console.log(result)

      if (result.creat) {
        window.location.href = context.getters.urlPersonal
      }

      context.commit('changeCreatError', !result.creat)
    }
  }
}
