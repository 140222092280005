<template lang="pug">
.button
  .button__text Upload
</template>

<script>
export default {
  name: 'Button'
}
</script>
