<template lang="pug">
.terms
  .terms__title(v-html="title")
  .terms__description(v-html="description")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Terms',

  computed: {
    ...mapGetters([
      'termsTitle',
      'termsDescription'
    ]),

    title () {
      return this.termsTitle
    },

    description () {
      return this.termsDescription
    }
  }
}
</script>
