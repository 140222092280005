<template lang="pug">
.form-login
  .form-login__fields
    FieldName
    FieldFullName
    FieldPhone
    FieldEmail
    FieldPassword
    FieldPasswordConfirm

  .form-login__terms
    Terms

  .form-login__consent
    Consent

  .form-login__error(v-if="error")
    Error

  .form-login__button
    Button(:isSend="v$.$invalid")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, sameAs, email } from '@vuelidate/validators'
import { mapGetters, mapMutations } from 'vuex'

import Consent from './components/common/Consent'
import Terms from './components/common/Terms'
import Button from './components/common/Button'

import FieldName from './components/fields/FieldName'
import FieldFullName from './components/fields/FieldFullName'
import FieldPhone from './components/fields/FieldPhone'
import FieldEmail from './components/fields/FieldEmail'
import FieldPassword from './components/fields/FieldPassword'
import FieldPasswordConfirm from './components/fields/FieldPasswordConfirm'

import Error from './components/common/Error'

export default {
  name: 'AppFormRegistration',

  components: {
    Consent,
    Terms,
    Button,
    FieldName,
    FieldFullName,
    FieldPhone,
    FieldEmail,
    FieldPassword,
    FieldPasswordConfirm,
    Error
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      objectConsent: {},
      objectForm: {}
    }
  },

  computed: {
    ...mapGetters([
        'fieldName',
        'fieldPhone',
        'fieldEmail',
        'fieldPassword',
        'fieldPasswordConfirm',
        'fieldConsent',
        'creatError'
    ]),

    name () {
      return this.fieldName
    },

    phone () {
      return this.fieldPhone
    },

    email () {
      return this.fieldEmail
    },

    password () {
      return this.fieldPassword
    },

    passwordConfirm () {
      return this.fieldPasswordConfirm
    },

    consent () {
      return this.fieldConsent
    },

    error () {
      return this.creatError
    }
  },

  methods: {
    ...mapMutations([
        'changeConsentText',
        'changeTermsTitle',
        'changeTermsDescription',
        'changeButtonText',
        'changeField'
    ])
  },

  validations () {
    return {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30)
      },

      phone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },

      email: {
        required,
        email
      },

      password: {
        required,
        minLength: minLength(15),
        maxLength: maxLength(15)
      },

      passwordConfirm: {
        required,
        sameAs: sameAs(this.password)
      },

      consent: {
        required
      }
    }
  },

  created () {
    this.objectConsent = window.appJobs.Consent
    this.objectForm = window.appJobs.FormRegistration
    this.changeConsentText(this.objectConsent.text)
    this.changeTermsTitle(this.objectConsent.terms.title)
    this.changeTermsDescription(this.objectConsent.terms.description)
    this.changeButtonText(this.objectForm.button)
  }
}
</script>
