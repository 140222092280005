/*global $*/
/*eslint no-undef: "error"*/

export default class Faq {
    classButton = 'faq-item__top'
    classItem = 'faq-item'
    classItemOpen = 'faq-item-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
