/*global $*/
/*eslint no-undef: "error"*/

export default class Comments {
    classBlock = 'comments'
    classButton = 'button-more-comments'
    classButtonHidden = 'button-more-hidden'
    classItemHidden = 'comment-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).find(`.${self.classItemHidden}`).toggleClass(self.classItemHidden)
            $(this).toggleClass(self.classButtonHidden)
        })
    }
}
