<template lang="pug">
.consent
  label.consent__wrapper
    input.consent__input(type="checkbox" v-model="value" value="consent")
    span.consent__blocks(@click="changeFieldValue()")
      span.consent__button
      span.consent__label(v-html="text" )
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Consent',

  data () {
    return {
      value: []
    }
  },

  computed: {
    ...mapGetters([
      'consentText'
    ]),

    text () {
      return this.consentText
    }
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    changeFieldValue () {
      this.changeField({
        name: 'fieldConsent',
        value: (this.value.length === 0) ? ['consent'] : []
      })
    }
  }
}
</script>
