<template lang="pug">
.form-error
  p.error.center Sorry, but your password or phone doesn't fit
</template>

<script>
export default {
  name: 'Error'
}
</script>
