<template lang="pug">
.button(
  :class="{'button-disable': isSend}"
  @click="send ()"
)
  .button__text(v-html="text")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Button',

  props: [
    'isSend'
  ],

  computed: {
    ...mapGetters([
        'buttonText',
        'fieldPhone',
        'fieldPassword',
    ]),

    text () {
      return this.buttonText
    }
  },

  methods: {
    ...mapActions([
        'actionLogin',
    ]),

    send () {
      if (!this.isSend) {
        this.actionLogin({
          phone: this.fieldPhone,
          password: this.fieldPassword
        })
      }
    }
  }
}
</script>
