<template lang="pug">
div
  .h4.center Topic created
  p.center Your topic will be added to the site soon.
</template>

<script>
export default {
  name: 'Success'
}
</script>
