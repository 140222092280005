export default {
    optionSize: 15,

    fieldFullName: '',
    fieldEmail: '',
    fieldUserName: '',
    fieldPhone: '',
    fieldPassword: '',
    fieldState: '',
    fieldImg: '',

    files: '',

    fieldConsent: [],
    consentText: '',

    termsTitle: '',
    termsDescription: '',

    cookieHash: '',
    cookieName: 'newjobs4you-login',
    urlRedirect: 'https://newjobs4you.com',

    buttonText: ''
}
