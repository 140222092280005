export default {
    changeField (state, payload) {
        state[ payload.name ] = payload.value
    },

    changeIsCreate (state, payload) {
        state.isCreate = payload
    },

    changeIsTopicCreate (state, payload) {
        state.isTopicCreate = payload
    }
}
