export default {
  optionSize            : 15,

  fieldName             : '',
  fieldFullName         : '',
  fieldEmail            : '',
  fieldPhone            : '',
  fieldPassword         : '',
  fieldPasswordConfirm  : '',
  fieldConsent          : [],

  consentText           : '',

  termsTitle            : '',
  termsDescription      : '',

  buttonText            : '',

  cookieName            : 'newjobs4you-login',
  cookieExpires         : 60,

  urlPersonal           : '/account/personal/',

  creatError            : false
}
