<template lang="pug">
.consent
  label.consent__wrapper
    input.consent__input(type="checkbox" value="consent" v-model="consent" @input="changeFieldValue")
    span.consent__blocks
      span.consent__button
      span.consent__label(v-html="text" )
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Consent',

  data() {
    return {
      isValue: false
    }
  },

  computed: {
    ...mapState({
      text: state => state.consentText,
      consent: state => state.fieldConsent
    }),
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    changeFieldValue () {
      this.isValue = !this.isValue

      this.changeField({
        name: 'fieldConsent',
        value: (this.isValue) ? ["consent"] : []
      })
    }
  }
}
</script>
