/*global $*/
/*eslint no-undef: "error"*/

export default class FilterSection {
    classButton = 'button-filter'
    classButtonView = 'button-filter-view'
    classBlock = 'sections'
    classView = 'sections-view'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonView)
            $(this).closest(`.${self.classBlock}`).toggleClass(self.classView)
        })
    }
}
