<template lang="pug">
.topic-create
  .topic-create__button(v-if="!create")
    Button
  .topic-create__form(v-if="create")
    Form(:field-sections="fieldSections" :field-user-id="fieldUserId")
</template>

<script>
import { mapGetters } from 'vuex'

import Button from '@/components/Topic/TopicCreate/components/Button'
import Form from '@/components/Topic/TopicCreate/components/Form'

export default {
  name: 'AppTopicCreate',

  props: [
      'fieldSections',
      'fieldUserId'
  ],

  components: {
    Button,
    Form
  },

  computed: {
    ...mapGetters([
        'isCreate'
    ]),

    create () {
      return this.isCreate
    }
  }
}
</script>
