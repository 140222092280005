import { createStore } from 'vuex'

export default createStore({
    state: {
        basePath: location.protocol + '//' + location.hostname,
        total: {
            filePath: '',
            count: ''
        }
    },
    mutations: {
        changeTotal (state, payload) {
            state.total = payload
        }
    },
    actions: {
        async Save (context, payload) {
            const response = await fetch(`/api/v1/save_form/${payload}/`, {
                method: 'GET'
            })

            const result = await response.json()
            context.commit('changeTotal', result)
            console.log(result)
        }
    },
    modules: {},
    getters: {
        isTotal: s => s.total
    }
})
