/*global $*/
/*eslint no-undef: "error"*/

export default class SearchButton {
    classButton = 'search--button'
    classBlock = 'search--block'
    classHidden = 'search-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classBlock}`).toggleClass(self.classHidden)
        })
    }
}
