/*global $*/
/*eslint no-undef: "error"*/

export default class States {
    classButton = 'button-more-states'
    classButtonHidden = 'button-more-hidden'

    classBlock = 'states'
    classItem = 'states__item'
    classItemHidden = 'states__item-hidden'

    constructor() {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).removeClass(self.classItemHidden)
            $(this).addClass(self.classButtonHidden)
        })
    }
}
