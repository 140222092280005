export default {
    optionSize: s => s.optionSize,

    fieldFullName: s => s.fieldFullName,
    fieldEmail: s => s.fieldEmail,
    fieldUserName: s => s.fieldUserName,
    fieldPhone: s => s.fieldPhone,
    fieldPassword: s => s.fieldPassword,
    fieldState: s => s.fieldState,
    fieldImg: s => s.fieldImg,

    files: s => s.files,

    fieldConsent: s => s.fieldConsent,
    consentText: s => s.consentText,

    termsTitle: s => s.termsTitle,
    termsDescription: s => s.termsDescription,

    cookieHash: s => s.cookieHash,
    cookieName: s => s.cookieName,
    urlRedirect: s => s.urlRedirect,

    buttonText: s => s.buttonText
}
