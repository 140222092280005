<template lang="pug">
.personal-img
  .personal-img__item
    img.personal-img__img(
      :src="imgSrc"
    )

  .personal-img__item
    .personal-img__uploaded
      p.last(:class="{'green': fileChange}" v-html="fileText")
      .personal-img__format jpg, jpeg, png

    input.personal-img__input(
      type="file"
      name="files"
      ref="files"
      id="files"
      @change="onFileChange"
    )

    label.personal-img__button-mobile(for="files")
      Button

  .personal-img__item
    label.personal-img__button(for="files")
      Button
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import Button from './components/Button'

export default {
  name: 'Image',

  components: {
    Button
  },

  data () {
    return {
      files: '',
      fileText: 'Photo uploaded',
      fileChange: false,
      imgSrc: ''
    }
  },

  computed: {
    ...mapGetters([
      'fieldImg',
      'cookieHash'
    ])
  },

  methods: {
    ...mapActions([
      'actionUpdateUserImg'
    ]),

    ...mapMutations([
        'changeField'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files
      this.fileChange = true

      this.changeFieldValue()

      if (this.fileChange) {
        this.fileText = 'Photo corrected'
      }

      // img download
      let file = this.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imgSrc = reader.result
      }

      // img upload
      this.actionUpdateUserImg({
        fields: {
          cookieHash: this.cookieHash
        },
        files: this.files
      })
    },

    changeFieldValue () {
      this.changeField({
        name: 'files',
        value: this.files
      })
    }
  },

  created () {
    this.imgSrc = this.fieldImg
  }
}
</script>
