<template lang="pug">
.input-text
  label.input-text__label(for="field-login-password-confirm") Confirm password *
  input.input-text__input(
    id="field-login-password-confirm"
    type="password"
    placeholder=""
    v-model.trim="value"
    @change="changeFieldValue()"
    @keyup="changeFieldValue()"
  )
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'FieldPasswordConfirm',

  data () {
    return {
      value: ''
    }
  },

  methods: {
    ...mapMutations([
      'changeField'
    ]),

    changeFieldValue () {
      this.changeField({
        name: 'fieldPasswordConfirm',
        value: this.value
      })
    }
  }
}
</script>
