/*global $*/
/*eslint no-undef: "error"*/

export default class LinkReferrer {
    classLink = 'link--referrer'
    nameSection = '/job-board/'
    nameForum = '/forum/'
    // nameSection = 'local'

    constructor () {
        let self = this
        let referrer = document.referrer
        let objLink = $(`.${self.classLink}`)

        if (objLink.length === 1) {
            if ((referrer !== '') && ((referrer.indexOf(this.nameSection) !== -1) || (referrer.indexOf(this.nameForum) !== -1))) {
                objLink.attr('href', referrer + '#' + objLink.data('anchor'))
            }
        }
    }
}
