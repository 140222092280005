export default {
  changeField (state, payload) {
    state[ payload.name ] = payload.value
  },

  changeConsentText (state, payload) {
    state.consentText = payload
  },

  changeTermsTitle (state, payload) {
    state.termsTitle = payload
  },

  changeTermsDescription (state, payload) {
    state.termsDescription = payload
  },

  changeButtonText (state, payload) {
    state.buttonText = payload
  },

  changeCreatError (state, payload) {
    state.creatError = payload
  }
}
