/*global $*/
/*global Cookies*/
/*eslint no-undef: "error"*/
export default class Political {
    classBlock = 'political--block'
    classButton = 'political--button'
    cookieName = 'political-newjobs4you'
    cookieValue = 'yes'

    classBlockOpen = 'political-open'

    constructor () {
        let self = this

        if (!Cookies.get(self.cookieName)) {
            $(`.${self.classBlock}`).addClass(self.classBlockOpen)

            $(`.${self.classButton}`).click(function () {
                $(`.${self.classBlock}`).removeClass(self.classBlockOpen)
                Cookies.set(self.cookieName, self.cookieValue)

                return false
            })
        }
    }
}
