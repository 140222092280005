/*global $*/
/*eslint no-undef: "error"*/

export default class Gallery {
    classBlock = 'gallery-more'
    classButton = 'button-more-gallery'
    classButtonHidden = 'button-more-hidden'
    classItemHidden = 'gallery-item-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(`.${self.classBlock}`).find(`.${self.classItemHidden}`).toggleClass(self.classItemHidden)
            $(this).toggleClass(self.classButtonHidden)
        })
    }
}
