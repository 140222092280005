<template lang="pug">
.form-apply
  .form-apply__fields
    .form-apply__field
      .input-text.input-text-gray
        label.input-text__label(for="apply-field-name") Full Name *
        input.input-text__input(
          id="apply-field-name"
          type="text"
          v-model.trim="fieldFullName"
          :class="{'input-text__input-error': v$.fieldFullName.$invalid && (fieldFullName.length > 0), 'input-text__input-complete': !v$.fieldFullName.$invalid && (fieldFullName.length > 0)}"
        )
    .form-apply__field
      .input-text.input-text-gray
        label.input-text__label(for="apply-field-state") State *
        input.input-text__input(
          id="apply-field-state"
          type="text"
          v-model.trim="fieldState"
          :class="{'input-text__input-error': v$.fieldState.$invalid && (fieldState.length > 0), 'input-text__input-complete': !v$.fieldState.$invalid && (fieldState.length > 0)}"
        )
    .form-apply__field
      .input-text.input-text-gray
        label.input-text__label(for="apply-field-email") E-mail *
        input.input-text__input(
          id="apply-field-email"
          type="email"
          v-model.trim="fieldEmail"
          :class="{'input-text__input-error': v$.fieldEmail.$invalid && (fieldEmail.length > 0), 'input-text__input-complete': !v$.fieldEmail.$invalid && (fieldEmail.length > 0)}"
        )
    .form-apply__field
      .input-text.input-text-gray
        label.input-text__label(for="apply-field-phone") Phone *
        input.input-text__input(
          id="apply-field-phone"
          type="tel"
          v-model.trim="fieldPhone"
          :class="{'input-text__input-error': v$.fieldPhone.$invalid && (fieldPhone.length > 0), 'input-text__input-complete': !v$.fieldPhone.$invalid && (fieldPhone.length > 0)}"
          v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
        )
  .form-apply__terms
    .form-apply__terms-title
      .input-text__label Terms
    p.small(v-html="filedTerms" )
  .form-apply__consent
    label.consent.consent-gray
      input.consent__input(type="checkbox" v-model="consent" value="consent" checked="checked")
      span.consent__blocks
        span.consent__button
        span.consent__label I agree to the Terms of Use & Privacy Policy*
  .form-apply__button
    .button(
      :class="{'button-disable': v$.$invalid}"
      @click="getSend()"
    )
      .button__text Submit
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormApply',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      files: '',
      fieldFullName: '',
      fieldEmail: '',
      fieldPhone: '',
      fieldState: '',
      filedTerms: '*By submitting this form, I expressly consent to be contacted by this employer and/or DriverMind via telephone or SMS. I understand that the calls and messages may be transmitted using an auto dialing system or prerecorded voice. Additionally, I agree to the following Terms of Service and Privacy Policy.',
      consent: []
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.Send({
          setting: {
            form: {
              formId: '4',
              checkField: {
                name: '',
                value: ''
              },
              isCheck: '0',
              pageId: window.appJobs.FormApply.pageId,
              altPage: window.appJobs.FormApply.altPage
            },
            mailDo: 'info@newjobs4you.com',
            emails: window.appJobs.FormApply.emails,
            title: 'New candidate',
            subject: '"Apply now" with Newjobs4you.com'
          },

          fields: [
            {
              name: 'name',
              title: 'Full Name',
              value: this.fieldFullName
            },
            {
              name: 'state',
              title: 'State',
              value: this.fieldState
            },
            {
              name: 'email',
              title: 'E-mail',
              value: this.fieldEmail
            },
            {
              name: 'phone',
              title: 'Phone',
              value: this.fieldPhone
            }
          ],
          files: this.files
        })

        localStorage.setItem(
            'fieldsFormApply',
            JSON.stringify(
                {
                  name: this.fieldFullName,
                  state: this.fieldState,
                  email: this.fieldEmail,
                  phone: this.fieldPhone
                }
            )
        )

        document.querySelector('#button-form-success').click()
        setTimeout(() => {
          this.consent = []
          $('.is-close').click()
        }, 3000)
      }
    }
  },

  validations () {
    return {
      fieldFullName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      fieldEmail: {
        required,
        email
      },
      fieldPhone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },
      fieldState: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30)
      },
      consent: {
        required
      }
    }
  },

  mounted () {
    if (localStorage.getItem('fieldsFormApply')) {
      let dateJson = JSON.parse(localStorage.getItem('fieldsFormApply'))
      this.fieldFullName = dateJson.name
      this.fieldState = dateJson.state
      this.fieldEmail = dateJson.email
      this.fieldPhone = dateJson.phone
    }
  }
}
</script>
