/*global $*/
/*eslint no-undef: "error"*/

export default class ReviewButton {
    classButton = 'button-more-review'
    classButtonHidden = 'button-more-hidden'

    classReview = 'review'
    classItem = 'review-item'
    classItemHidden = 'review-item-hidden'

    constructor() {
        let self = this

        $(`.${self.classButton}`).click(function () {
            // let count = parseInt($(`.${self.classReview}`).data('count'))
            $(`.${self.classReview}`).find(`.${self.classItem}`).removeClass(self.classItemHidden)
            $(this).addClass(self.classButtonHidden)
        })
    }
}
