export default {
    actionPasswordGeneration (context) {
        let password = ''
        let CharacterSet = ''
        let optionSize = context.getters.optionSize

        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        CharacterSet += '0123456789'
        CharacterSet += '![]{}()%&*$#^<>~@|'

        for(let i=0; i < optionSize; i++) {
            password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
        }
        context.commit('changeField', {name: 'fieldPassword', value: password})
    },

    async actionUpdateUser (context, payload) {
        if ((process.env.NODE_ENV === 'development')) {
            console.log(payload)
        } else {
            const fields = {
                fields: payload.fields
            }

            const formData = new FormData()
            formData.append('fields', JSON.stringify(fields))

            if (payload.files !== '') {
                for (let i = 0; i < payload.files.length; i++) {
                    const file = payload.files[i]
                    formData.append('files[' + i + ']', file)
                }
            }

            const response = await fetch(`/api/v1/user/update/`, {
                method: 'POST',
                body: formData
            })

            const result = await response.json()
            console.log(result)
        }
    },

    async actionUpdateUserImg (context, payload) {
        if ((process.env.NODE_ENV === 'development')) {
            console.log(payload)
        } else {
            const fields = {
                fields: payload.fields
            }

            const formData = new FormData()
            formData.append('fields', JSON.stringify(fields))

            if (payload.files !== '') {
                for (let i = 0; i < payload.files.length; i++) {
                    const file = payload.files[i]
                    formData.append('files[' + i + ']', file)
                }
            }

            const response = await fetch(`/api/v1/user/update/img/`, {
                method: 'POST',
                body: formData
            })

            const result = await response.json()
            console.log(result)
        }
    }
}
