<template lang="pug">
.button(
  :class="{'button-disable': isSend}"
  @click="creatUser()"
)
  .button__text(v-html="text")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Button',

  props: [
    'isSend'
  ],

  computed: {
    ...mapGetters([
        'buttonText',
        'fieldName',
        'fieldFullName',
        'fieldEmail',
        'fieldPhone',
        'fieldPassword'
    ]),

    text () {
      return this.buttonText
    }
  },

  methods: {
    ...mapActions([
        'actionCreatUser'
    ]),

    creatUser() {
      if (!this.isSend) {
        this.actionCreatUser({
          name:     this.fieldName,
          fullName: this.fieldFullName,
          email:    this.fieldEmail,
          phone:    this.fieldPhone,
          password: this.fieldPassword
        })
      }
    }
  }
}
</script>
